<template>
    <div id="AsoExame">
        <b-card-group deck>
            <b-card title="" body-class="text-center" header-tag="nav" style="max-width: 80rem;">
                <template #header>
                    <b-nav card-header tabs>
                        <b-nav-item active><b>Upload EXAMES</b></b-nav-item>
                    </b-nav>
                </template>
                <b-container style="margin-top: 40px; margin-left: 10px">
                    <b-form @submit.prevent="save">
                        <b-row>
                            <b-col>
                                <b-form-group class="fonteItem" label="Nome:" label-for="nested-country"
                                    label-cols-sm="3" label-align-sm="left">
                                    <label class="fonteCampo"> &nbsp;{{ dadosPaciente.nome }} </label>
                                    <b-form-input hidden="true" id="pacienteid" name="pacienteid"
                                        v-model="formdata.paciente_id"></b-form-input>
                                    <b-form-input hidden="true" id="empresaid" name="empresaid"
                                        v-model="formdata.empresa_id"></b-form-input>
                                    <b-form-input hidden="true" id="funcaoid" name="funcaoid"
                                        v-model="formdata.funcao_id"></b-form-input>
                                </b-form-group>
                            </b-col>

                            <b-col>
                                <b-form-group class="fonteItem" label="Cpf:" label-for="nested-country"
                                    label-cols-sm="3" label-align-sm="left"><label class="fonteCampo">&nbsp;{{
                                        dadosPaciente.cpf }}</label>
                                </b-form-group>
                            </b-col>

                            <b-col>
                                <b-form-group class="fonteItem" label="Função:" label-for="nested-country"
                                    label-cols-sm="3" label-align-sm="left"><label class="fonteCampo">&nbsp;{{
                                        dadosPaciente.funcao.name }}</label>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col>
                                <b-form-group class="fonteItem" label="Empresa:" label-for="nested-country"
                                    label-cols-sm="3" label-align-sm="left"><label class="fonteCampo">&nbsp;{{
                                        dadosPaciente.empresa.nome }} </label>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col>
                                <b-form-group class="fonteItem" label="Cnpj/Cpf:" label-for="nested-country"
                                    label-cols-sm="3" label-align-sm="left"><label class="fonteCampo">&nbsp;{{
                                        dadosPaciente.empresa.cnpj }} / {{ dadosPaciente.empresa.cpf }}</label>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group class="fonteItem" label="Convênio:" label-for="nested-country"
                                    label-cols-sm="3" label-align-sm="left"><label class="fonteCampo">&nbsp;{{
                                        dadosPaciente.empresa.convenio ? 'ATIVO' : 'DESATIVADO'
                                        }} </label>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col>
                                <b-form-group class="fonteItem" label="Obervação:" label-for="nested-country"
                                    label-cols-sm="3" label-align-sm="left"><label class="fonteTituloFormRed">{{
                                        dadosPaciente.empresa.observacao
                                        }}</label>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <!-- FIM DADOS DO PACIENTE E DA EMPRESA  -->
                        <b-card header="Ficha de Exame" header-class="fonteTituloHeader" header-tag="header" footer=""
                            footer-tag="footer">
                            <b-row>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <b-col md="2" sm="12" class="alinhaFormAso">
                                    <b-form-group class="mb-3" label="">
                                        <b-card-text><label class="fonteTituloForm">Tipo Aso:&nbsp; {{
                                                formdata.tipoaso.descricao }}</label>

                                        </b-card-text>
                                    </b-form-group>
                                </b-col>

                                <b-col md="2" sm="12" class="alinhaFormAso">
                                    <b-form-group class="mb-3">
                                        <b-card-text><label class="fonteTituloForm">Tipo Pagamento:&nbsp; {{
                                                formdata.tipopagamento.descricao }}</label>

                                        </b-card-text>
                                    </b-form-group>
                                </b-col>

                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <b-col md="2" sm="12" class="alinhaFormAso">
                                    <b-form-group class="mb-3">
                                        <b-card-text><label class="fonteTituloForm">Médico: &nbsp; {{
                                                formdata.medico.nome }}</label>

                                        </b-card-text>
                                    </b-form-group>
                                </b-col>
                                &nbsp;

                                <b-col md="2" sm="12" class="alinhaFormAso">
                                    <b-form-group class="mb-3"><b-card-text><label class="fonteTituloForm">Data
                                                Aso:&nbsp; {{ formatarDataEntradaParaGrid(formdata.data_criacao)
                                                }}</label>


                                        </b-card-text>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <br />
                            <b-row>
                                <b-col lg="12" class="pb-2">
                                    <b-button type="button" @click="impressaoAso" pill size="sm" variant="success"
                                        :class="visibleBtAso">
                                        <span class="fonteCampoBotaoASO">Imprimir ASO </span>
                                    </b-button>
                                    &nbsp;
                                </b-col>
                            </b-row>
                            <br />

                            <!-- PAINEL DE IMPRESSAO DA ASO, FICHA CLINICA E ADD EXAMES COMPLEMENTARES -->
                            <b-row>
                                <b-col :class="visiblePrint" cols="3">
                                    <template>
                                        <div>
                                            <aso-pdf :dados="dadosAso"></aso-pdf>
                                        </div>
                                    </template>
                                </b-col>

                                <b-col cols="3">
                                    <template>
                                        <div :class="visiblePrint">
                                            <recibo-aso-pdf :dados="dadosAso"></recibo-aso-pdf>
                                        </div>
                                    </template>
                                </b-col>

                                <b-col cols="3">
                                    <template>
                                        <div :class="visiblePrint">
                                            <ficha-clinica-aso-pdf :dados="dadosAso"></ficha-clinica-aso-pdf>
                                        </div>
                                    </template>
                                </b-col>
                                <b-col cols="3">
                                    <template>
                                        <div :class="visiblePrint">
                                            <encaminhamento-pdf :dados="dadosAso"></encaminhamento-pdf>
                                        </div>
                                    </template>
                                </b-col>

                            </b-row>



                        </b-card>
                    </b-form>
                </b-container>

                <!-- Info modal ADICIONAR EXAMES COMPLEMENTARES -->
                <b-modal size="xl" v-model="modalShow" title="Desconto Exames" hide-footer>
                    <b-form @submit.prevent="save">
                        <b-form-group id="input-group-2" label="Nome:" label-for="input-2">
                            <b-form-input id="input-2" hidden="true" v-model="formdataExames.id"
                                placeholder="id"></b-form-input>
                            <b-form-input id="input-2" disabled v-model="formdataExames.exame.name" required
                                placeholder="Exame"></b-form-input>
                        </b-form-group>

                        <b-form-group id="input-group-3" label="Valor Atual:" label-for="input-3">
                            <b-form-input id="input-3" disabled v-model="formdataExames.valorexame"
                                required></b-form-input>
                        </b-form-group>

                        <b-form-group id="input-group-3" label="Novo Valor com Desconto:" label-for="input-3">
                            <b-form-input id="input-3" v-model="formdataExames.valorexamesemdesconto" required
                                placeholder="Novo Valor" :formatter="formatNumeroToReal"></b-form-input>
                        </b-form-group>

                        <br />
                        <b-button size="sm" pill variant="danger" @click="close">
                            Cancelar
                        </b-button>
                        &nbsp;
                        <b-button type="submit" pill size="sm" variant="success">
                            Salvar
                        </b-button>
                    </b-form>
                </b-modal>
                <!--  LISTA DE EXAMES -->
                <template hidden="true">
                    <div>
                        <b-table striped hover responsive id="my-table2" :items="dadosExamesAso"
                            :fields="visibleFields2">
                            <template #cell(opcao)="data">

                                &nbsp;
                                <audiometria-pdf :hidden="iconVisibleAudiometria(data.item.exame.id)"
                                    :dados="formdataPesquisa.aso_id"></audiometria-pdf>
                                <acuidade-pdf :hidden="iconVisibleAcuidade(data.item.exame.id)"
                                    :dados="formdataPesquisa.aso_id"></acuidade-pdf>
                                <laudo-pcd-pfd :hidden="iconVisibleLaudoPcd(data.item.exame.id)"
                                    :dados="formdataPesquisa.aso_id"></laudo-pcd-pfd>
                                <romberg-pdf :hidden="iconVisibleRomberg(data.item.exame.id)"
                                    :dados="formdataPesquisa.aso_id"></romberg-pdf>
                                <psicosocial-pdf :hidden="iconVisiblePsicoSocial(data.item.exame.id)"
                                    :dados="formdataPesquisa.aso_id"></psicosocial-pdf>
                                <teste-cores-pdf :hidden="iconVisibleTesteCores(data.item.exame.id)"
                                    :dados="data.item.aso_id"></teste-cores-pdf>
                                &nbsp;
                                <b-icon icon="cloud-upload-fill" cursor="pointer" @click="editItem(data.item)" pill
                                    variant="primary" size="sm">
                                </b-icon>
                                <div>
                                    <input type="file" name="file" multiple ref="files" />
                                    <button @click="sendFile">Send</button>
                                </div>
                            </template>
                        </b-table>
                    </div>
                </template>

            </b-card>
        </b-card-group>
        <teste> </teste>
    </div>



</template>
<script>

//import jsPDF from 'jspdf' 
import { http } from '@/plugins/axios'
import { mapState, mapActions } from 'vuex'
import maskMixins from '@/helpers/mixins/mask'
import AsoPdf from '../../../components/imprimir/AsoPdf.vue'
import ReciboAsoPdf from '../../../components/imprimir/ReciboAsoPdf.vue'
import FichaClinicaAsoPdf from '../../../components/imprimir/FichaClinicaAsoPdf.vue'
import AudiometriaPdf from '../../../components/imprimir/AudiometriaPdf.vue'
import AcuidadePdf from '../../../components/imprimir/AcuidadePdf.vue'
import LaudoPcdPfd from '../../../components/imprimir/LaudoPcdPfd.vue'
import RombergPdf from '../../../components/imprimir/RombergPdf.vue'
import PsicosocialPdf from '../../../components/imprimir/PsicosocialPdf.vue'
import TesteCoresPdf from '../../../components/imprimir/TesteCoresPdf.vue'
import EncaminhamentoPdf from '../../../components/imprimir/EncaminhamentoPdf.vue'

import teste from '../../../components/imprimir/teste.vue'

const moment = require("moment");

export default {
    components: { teste, AsoPdf, ReciboAsoPdf, FichaClinicaAsoPdf, AudiometriaPdf, AcuidadePdf, LaudoPcdPfd, RombergPdf, PsicosocialPdf, TesteCoresPdf, EncaminhamentoPdf },
    mixins: [maskMixins],
    name: 'AsoPdf2',
    data() {
        return {
            dadosExamesAso: [],
            listTipoPagamento: '',
            itemsExames: [
                {
                    key: 'id',
                    label: 'id',
                    visible: false,
                    // sortable: true,
                    thStyle: { width: "10%" },
                    tdClass: 'fonteLinhasLeft'
                },
                {
                    key: 'exame.name',
                    label: 'Exame',
                    visible: true,
                    sortable: true,
                    thStyle: { width: "10%" },
                    tdClass: 'fonteLinhasLeft'
                },
                {
                    key: 'valorexame',
                    label: 'Valor ',
                    visible: true,
                    sortable: true,
                    thStyle: { width: "10%" },
                    
                    formatter: 'convertNumberToReal'
                },
                {
                    key: 'opcao',
                    visible: true,
                    thStyle: { width: "7%" },
                    label: 'Opções',
                    sortable: true
                }
            ],
            perPage: 10,
            currentPage: 1,
            filter: null,
            infoModal: {
                id: 'info-modal',
                title: 'Adicionar Desconto Exame',
                content: ''
            },
            dadosExames: {},

            dadosPaciente: {
                id: '',
                nome: '',
                cpf: '',
                funcao: {
                    name: ''
                },
                empresa: {
                    id: '',
                    nome: '',
                    cnpj: '',
                    cpf: '',
                    convenio: ''
                }
            },
            formdataExames: {
                valorexamesemdesconto: '',
                novovalorexame: '',
                exame: {
                    name: ''
                }
            },
            formdata: {
                tipoaso_id: '',
                empresa_id: '',
                datahoje: '',
                dataemissaoaso: '',
                tipopagamento_id: '',
                paciente_id: '',
                funcao_id: '',
                medico_id: '',
                medico: {
                    nome: ''
                },
                tipopagamento: {
                    descricao: ''
                },
                tipoaso: {
                    descricao: ''
                }
            },
            perfil_usuario: '',
            dadosAso: '',
            modalShow: false,
            editedIndex: -1,
            formdataPesquisa: {
                id: '',
                convenio_empresa: false
            },
            visiblePrint: 'invisible',
            visibleBtAso: 'visible',
            visibleBtExames: 'visible'
        }
    },
    mounted() {
        this.buscaExamesAso(),
            this.buscaAsoPaciente(),
            this.buscaExames()
    },
    created() {
        this.formdataPesquisa = {
            id: this.$route.query.paciente_id,
            aso_id: this.$route.query.aso_id,
            convenio_empresa: this.$route.query.convenio_empresa,
        }
    },
    computed: {

        ...mapState('auth', ['user']),
        ...mapState('aso', ['tableTipoAso']),
        ...mapState('medico', ['tableMedicos']),

        visibleFields() {
            return this.fields.filter(field => field.visible)
        },

        visibleFields2() {
            return this.itemsExames.filter(field => field.visible)
        },

        rows() {
            return this.dadosExames.length
        },

        now() {
            let hoje = new Date()
            let data_formatada = hoje.toLocaleDateString('pt-BR', {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric'
            })
            return data_formatada
        },
        dataConvertBr(data) {
            let data_formatada = data.toLocaleDateString('pt-BR', {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric'
            })
            return data_formatada
        },
    },

    methods: {

        async sendFile() {

            let dataForm = new FormData();

            for (let file of this.$refs.files.files) {

                dataForm.append(`file`, file);

            }

            // let res = await fetch(`http://localhost:3000/upload`, {
            //     method: 'POST',
            //     body: dataForm,
            // });
            // await http.post('/examesaso/desconto/' + this.formdataExames.id, this.formdataExames).then(resp => {

            let res = await http.post('exameaso/upload/arquivo/', dataForm);
            let data = await res.json();
            console.log(data);

        },

        ...mapActions('paciente', ['ActionFindByPaciente']),
        ...mapActions('aso', ['ActionSalvar']),
        ...mapActions('exame', ['ActionFindExame']),

        createItem() {

            this.modalShow = true;

        },

        iconVisible(perfil) {

            if (perfil === 'administrador') {
                return false
            }

            else {
                return true
            }

        },

        iconVisibleTesteCores(idexame) {

            if (idexame === 'a86deaf3-8719-45bc-8afe-48a3892b0c3e') {
                return false
            }

            else {
                return true
            }

        },

        iconVisibleAudiometria(idexame) {

            if (idexame === '8ad2ca34-7f1c-498c-b2ea-f267b01fdd00') {
                return false
            }

            else {
                return true
            }

        },

        iconVisibleLaudoPcd(idexame) {

            if (idexame === 'a9a11aaf-3418-4406-bdb9-a9751d08defb') {
                return false
            }

            else {
                return true
            }

        },

        iconVisibleAcuidade(idexame) {

            if (idexame === '85e1e173-101e-4b61-9273-84c61e99ea24') {
                return false
            }

            else {
                return true
            }

        },

        iconVisibleRomberg(idexame) {

            if (idexame === '12d18c73-85e2-42e4-9a82-cb0501995ff6') {
                return false
            }

            else {
                return true
            }
        },

        iconVisiblePsicoSocial(idexame) {

            if (idexame === 'c9aa9410-5181-400a-b2dd-126f54cdb1c1') {
                return false
            }

            else {
                return true
            }

        },

        editItem(item) {

            this.modalShow = true;
            this.editedIndex = this.dadosExamesAso.indexOf(item);
            this.formdataExames = Object.assign({}, item);

        },

        close() {

            this.modalShow = false;
            setTimeout(() => {
                this.editedIndex = -1;
                this.buscaExamesAso()
            }, 300);

        },

        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },

        async buscaAsoPaciente() {

            await http.get('/aso/' + this.$route.query.aso_id).then(resp => {
              //  this.editedIndex = this.fields.indexOf(resp.data);
                this.formdata = resp.data;
                this.formdata.datahoje = moment(resp.data.dataemissaoas).format("DD/MM/yyyy")
                // Object.assign({}, resp.data);

            })

        },

        async buscaExames() {
            //pesquisa se a empresa deste paciente possui convenio para exames
            await http.get('/paciente/' + this.formdataPesquisa.id).then(resp => {

                //PREENCHER FORMULARIO
                this.dadosPaciente = resp.data;
                this.dadosPaciente.empresa.convenio = resp.data.empresa.convenio;
                this.dadosPaciente.empresa.id = resp.data.empresa.id;
            })

            if (this.dadosPaciente.empresa.convenio) {

                //entra para buscar os exames caso tenha convenio
                await http.get('convenioempresa/' + this.dadosPaciente.empresa.id).then(resp => {
                    (resp.data).forEach(function (item) {//tratando as datas 
                        item.name = item.exame.name
                        item.id = item.exame.id
                        item.valoravista = item.valorexame
                        delete item.empresa_id;
                        delete item.user_id;
                        delete item.empresa;
                        delete item.exame;
                        delete item.user;
                    })
                    this.dadosExames = resp.data
                })
            }

            else {
                //entra para buscar os exames caso não tenha convenio
                await http.get('exame/').then(resp => {
                    this.dadosExames = resp.data
                })
            }
        },

        async buscaExamesAso() {

            await http.get('/examesaso/' + this.formdataPesquisa.aso_id).then(resp => {
                this.dadosExamesAso = resp.data;
            })
        },

        impressaoAso() {
            this.visiblePrint = 'visible';
            this.visibleBtExames = 'visible';
            this.dadosAso = this.formdataPesquisa.aso_id
        },

        async deleteItem(data) {

            const index = this.dadosExamesAso.indexOf(data);

            if (confirm('Deseja excluir o item?') && this.dadosExamesAso.splice(index, 1)) {
                await http.delete('/examesaso/' + data.id + '/' + this.user.id).then(resp => {

                    if (resp.data) {
                        alert('Exame excluido com sucesso!')
                    }
                    this.buscaExamesAso()

                })
            }
        },
    }
}
</script>
<style>
.fonteCampoBotaoASO {

    color: #f5f8f7;
    font-size: 14px;
    font: bold 15px arial, verdana, helvetica, sans-serif;
    text-align: center;
    font-size: 16px;
    letter-spacing: -1px;
    line-height: 20px;

}

.alinhaFormAso {
    margin-left: 50px;
}

.fonteItem {
    font-size: 14px;
    font: bold 10px arial, verdana, helvetica, sans-serif;
    text-align: left;
    font-size: 16px;
    letter-spacing: -1px;
    line-height: 20px;
}

.input {
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    box-shadow: 1px 1px 2px #333333;
    -moz-box-shadow: 1px 1px 2px #333333;
    -webkit-box-shadow: 1px 1px 2px #333333;
    background: #d8d7d7;
    border: 1px solid #000000;
    width: 150px;
    height: 25px;
}

.fonteTituloHeader {
    background-color: #e2e4e8;
    color: #31423b;
    font-size: 14px;
    font: bold 15px arial, verdana, helvetica, sans-serif;
    text-align: center;
    font-size: 16px;
    letter-spacing: -1px;
    line-height: 20px;
}

.fonteTituloFormRed {
    color: #c72126;
    font-size: 14px;
    font: bold 15px arial, verdana, helvetica, sans-serif;
    text-align: left;
    font-size: 16px;
    letter-spacing: -1px;
    line-height: 20px;
}

.fonteTituloForm {
    color: #31423b;
    font-size: 14px;
    font: bold 15px arial, verdana, helvetica, sans-serif;
    text-align: left;
    font-size: 16px;
    letter-spacing: -1px;
    line-height: 20px;
}

.fonteCampo {
    color: #747474;
    text-transform: uppercase;
    font-size: 16px;
    font: bold 15px arial, verdana, helvetica, sans-serif;
    text-align: left
}

.fonteCampoBotao {
    color: #747474;
    text-transform: uppercase;
    font-size: 16px;
    font: bold 15px arial, verdana, helvetica, sans-serif;
    text-align: left;
    list-style: none;
}

.fonteCampoBotao:hover,
.active {
    color: #f0f5f0;
}

.fonteLinhas {
    font-size: 14px;
    text-align: left
}

.fonteLinhasLeft {
    font-size: 14px;

}

.select-selected {
    border-color: black;
    border: 6px solid;
}

.select-selected.select-arrow-active:after {
    border-color: black;
    top: 7px;
}

.select-items div,
.select-selected {
    color: black;
    padding: 8px 16px;
    border: 1px solid;
    border-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.select-items {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
}

.select-hide {
    display: none;
}

.pesquisa_select {
    position: relative;
    margin-top: 20px;
}

.tituloFormulario {
    background-color: #a2a9b7;
    display: block;
    color: aliceblue;
    text-align: left;
}

.select-items div:hover,
.same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
}

.visible {
    visibility: visible;
}

.invisible {
    visibility: hidden;
}

@mixin flex-center($columns: false) {
    display: flex;
    align-items: center;
    justify-content: center;

    @if $columns {
        flex-direction: column;
    }
}
</style>